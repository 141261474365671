import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowUpDownIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.12322 4.6875H7.0019L2.46973 9.21967L3.53039 10.2803L7.31256 6.49816V18.5625H8.81256V6.49816L12.5947 10.2803L13.6554 9.21967L9.12322 4.6875Z"
          fill={color}
        />
        <path
          d="M16.9982 19.3125H14.8769L10.3447 14.7803L11.4054 13.7197L15.1876 17.5018V5.4375H16.6876V17.5018L20.4697 13.7197L21.5304 14.7803L16.9982 19.3125Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowUpDownIcon;
