import React, { useState, useEffect } from "react";
import { getYear } from "date-fns/fp";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useMediaQuery, {
  LARGER_THAN_LAPTOP,
} from "storefront/hooks/useMediaQuery";
import useAuthentication from "storefront/hooks/useAuthentication";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import LinkButton from "storefront/components/LinkButton";
import trustMicrositeViewed from "storefront/Analytics/EventCreators/trustMicrositeViewed";
import useAnalytics from "storefront/hooks/useAnalytics";
import InstagramLogo from "storefront/components/Icons/InstagramLogo";
import FacebookLogo from "storefront/components/Icons/FacebookLogo";
import YoutubeLogo from "storefront/components/Icons/YoutubeLogo";
import LinkedinLogo from "storefront/components/Icons/LinkedinLogo";
import MobileFooter from "./MobileFooter/index";
import SiteLink from "./SiteLink";
import SocialLink from "./SocialLink";

// eslint-disable-next-line css-modules/no-unused-class
import siteLinkStyles from "./SiteLink.module.scss";
import styles from "./Footer.module.scss";

const Footer = () => {
  const [hideTopBorder, setHideTopBorder] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const { t } = useTranslation("common");
  const { track } = useAnalytics();
  const isLargerThanLaptop = useMediaQuery(LARGER_THAN_LAPTOP);
  const auth = useAuthentication();

  const newHomepageFlag =
    auth.type === "Authenticated"
      ? Feature.NEW_HOMEPAGE_LOGGED_IN
      : Feature.NEW_HOMEPAGE_LOGGED_OUT;
  const newHomepageEnabledResource = useFeatureFlag(newHomepageFlag);

  useEffect(() => {
    // NOTE(McRae): these pages have a non-white background at the end so we do not want a top border
    const isAboutPage = window.location.pathname === "/about";
    const isArticleShowPage = window.location.pathname.includes(
      `${t("BLOG_LINK_PATH")}/`,
    );

    if (isAboutPage || isArticleShowPage) {
      setHideTopBorder(true);
      return;
    }

    if (newHomepageEnabledResource.type === "Loading") return;

    const isLegacyGrailedHomePage =
      window.location.pathname === "/" &&
      (newHomepageEnabledResource.type === "Failed" ||
        !newHomepageEnabledResource.value);

    if (isLegacyGrailedHomePage) setHideTopBorder(true);
  }, [t, newHomepageEnabledResource]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <footer
      className={classNames(styles.pageFooter, {
        [styles.isBorderless]: hideTopBorder,
      })}
    >
      {!isLargerThanLaptop ? <MobileFooter /> : null}
      <div className={styles.links}>
        <SiteLink className={styles.siteLink} href="/about" rel="nofollow">
          About
        </SiteLink>
        {mounted ? (
          <LinkButton
            // noop -- the modal opens magically
            onClick={() => {}}
            variant="inherit"
            className={classNames(
              "ot-sdk-show-settings", // this className handles the modal opening & closing
              styles.siteLink,
              siteLinkStyles.siteLink,
            )}
          >
            Your Privacy Choices
          </LinkButton>
        ) : null}
        <SiteLink
          className={styles.siteLink}
          href={t("HELP_PAGE_URL")}
          rel="nofollow"
        >
          Help &amp; FAQ
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="/about/terms"
          rel="nofollow"
        >
          Terms
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="/about/privacy"
          rel="nofollow"
        >
          Privacy
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="/trust"
          onClick={() => track(trustMicrositeViewed("footer"))}
          rel="nofollow"
        >
          Trust
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="/about/accessibility"
          rel="nofollow"
        >
          Accessibility
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="/about/contact"
          rel="nofollow"
        >
          Contact
        </SiteLink>
        <SiteLink
          className={styles.siteLink}
          href="https://boards.greenhouse.io/grailed"
          rel="nofollow"
        >
          Jobs
        </SiteLink>
        {t("IOS_APP_ID") ? (
          <SiteLink
            className={styles.siteLink}
            href={`https://itunes.apple.com/app/${t("IOS_APP_ID")}`}
            rel="noopener noreferrer nofollow"
          >
            iOS App
          </SiteLink>
        ) : null}

        {t("INSTAGRAM_USERNAME") ? (
          <SocialLink
            className={styles.socialLink}
            href={`https://www.instagram.com/${t("INSTAGRAM_USERNAME")}`}
          >
            <InstagramLogo />
          </SocialLink>
        ) : null}
        {t("FACEBOOK_USERNAME") ? (
          <SocialLink
            className={styles.socialLink}
            href={`https://www.facebook.com/${t("FACEBOOK_USERNAME")}`}
          >
            <FacebookLogo />
          </SocialLink>
        ) : null}
        {t("YOUTUBE_USERNAME") ? (
          <SocialLink
            className={styles.socialLink}
            href={`https://www.youtube.com/channel/${t("YOUTUBE_USERNAME")}`}
          >
            <YoutubeLogo />
          </SocialLink>
        ) : null}
        {t("LINKEDIN_USERNAME") ? (
          <SocialLink
            className={styles.socialLink}
            href={`https://www.linkedin.com/company/${t("LINKEDIN_USERNAME")}`}
          >
            <LinkedinLogo />
          </SocialLink>
        ) : null}

        <span className={styles.copyright}>
          {t("MARKETPLACE_ID")} &copy; {getYear(new Date())}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
