import React from "react";

import X from "storefront/components/Icons/Navigation/X";
import { SearchIcon } from "storefront/ui/icons";
import { Input } from "storefront/ui/input";
import { cn } from "storefront/ui/utils";

import styles from "./DesktopSearch.module.scss";

const DesktopSearch = () => {
  const [inputIsFocused, setInputIsFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSearchIconClick = () => {
    inputRef.current?.focus();
  };

  const handleClearIconClick = () => {
    inputRef.current?.blur();
  };

  const onInputFocus = () => {
    setInputIsFocused(true);
  };

  const onInputBlur = () => {
    setInputIsFocused(false);
  };

  return (
    <div className={styles.wrapper}>
      {!inputIsFocused ? (
        <button
          type="button"
          className={cn(styles.search, styles.button)}
          aria-label="Open search"
          onClick={handleSearchIconClick}
          tabIndex={-1}
        >
          <SearchIcon />
        </button>
      ) : (
        <button
          type="button"
          className={cn(styles.clear, styles.button)}
          aria-label="Clear search"
          onClick={handleClearIconClick}
          tabIndex={-1}
        >
          <X />
        </button>
      )}
      <Input
        ref={inputRef}
        className={styles.input}
        placeholder="Search for anything"
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
    </div>
  );
};

export default DesktopSearch;
