import React from "react";
import { Icon } from "storefront/Icon";

const CircleCross = (props: Icon) => (
  <svg
    height={props.height || "16px"}
    width={props.width || "17px"}
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM4.16732 11.5246L7.19183 8.50004L4.16732 5.47553L4.97544 4.66741L7.99995 7.69192L11.0245 4.66741L11.8326 5.47553L8.80807 8.50004L11.8326 11.5246L11.0245 12.3327L7.99995 9.30817L4.97544 12.3327L4.16732 11.5246Z"
    />
  </svg>
);

export default CircleCross;
