import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CaretUpIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3107 10.25L6.53033 15.0303L5.46967 13.9697L10.6893 8.75L13.3107 8.75L18.5303 13.9697L17.4697 15.0303L12.6893 10.25L11.3107 10.25Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CaretUpIcon;
