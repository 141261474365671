import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ConditionIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.625 3.41032L8.625 4.08968L9.23436 4.28255C9.58347 4.39305 9.85695 4.66653 9.96745 5.01564L10.1603 5.625H10.8397L11.0326 5.01564C11.143 4.66653 11.4165 4.39305 11.7656 4.28255L12.375 4.08968V3.41032L11.7656 3.21745C11.4165 3.10695 11.143 2.83347 11.0326 2.48436L10.8397 1.875L10.1603 1.875L9.96745 2.48436C9.85695 2.83347 9.58347 3.10695 9.23436 3.21745L8.625 3.41032Z"
          fill={color}
        />
        <path
          d="M1.875 7.84528L1.875 8.65472L2.6834 8.91059C3.59107 9.19788 4.30212 9.90894 4.58941 10.8166L4.84528 11.625H5.65472L5.91059 10.8166C6.19788 9.90894 6.90894 9.19788 7.8166 8.91059L8.625 8.65472V7.84528L7.8166 7.58941C6.90894 7.30212 6.19788 6.59106 5.91059 5.6834L5.65472 4.875L4.84528 4.875L4.58941 5.6834C4.30213 6.59106 3.59106 7.30212 2.6834 7.58941L1.875 7.84528Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 16.3587L6.75 13.6413L9.18746 12.8698C10.5839 12.4278 11.6778 11.3339 12.1198 9.93746L12.8913 7.5L15.6087 7.5L16.3802 9.93746C16.8222 11.3339 17.9161 12.4278 19.3125 12.8698L21.75 13.6413V16.3587L19.3125 17.1302C17.9161 17.5722 16.8222 18.6661 16.3802 20.0625L15.6087 22.5H12.8913L12.1198 20.0625C11.6778 18.6661 10.5839 17.5722 9.18746 17.1302L6.75 16.3587ZM13.5499 10.3901C13.5218 10.4786 13.4919 10.5662 13.46 10.6529C12.8529 12.3021 11.5521 13.6029 9.90287 14.21C9.81622 14.2419 9.72861 14.2718 9.64009 14.2999L8.25 14.7398V15.2602L9.64009 15.7001C9.72861 15.7282 9.81622 15.7581 9.90287 15.79C11.5521 16.3971 12.8529 17.6979 13.46 19.3471C13.4919 19.4338 13.5218 19.5214 13.5499 19.6099L13.9898 21H14.5102L14.9501 19.6099C14.9782 19.5214 15.0081 19.4338 15.04 19.3471C15.6471 17.698 16.9479 16.3972 18.5971 15.79C18.6838 15.7581 18.7714 15.7282 18.8599 15.7001L20.25 15.2602V14.7398L18.8599 14.2999C18.7714 14.2718 18.6838 14.2419 18.5971 14.21C16.9479 13.6029 15.6472 12.3021 15.04 10.6529C15.0081 10.5662 14.9782 10.4786 14.9501 10.3901L14.5102 9H13.9898L13.5499 10.3901Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ConditionIcon;
