import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CircleCrossKnockoutIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={forwardedRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM6.9696 15.9697L10.9393 12.0001L6.9696 8.03039L8.03026 6.96973L11.9999 10.9394L15.9696 6.96973L17.0303 8.03039L13.0606 12.0001L17.0303 15.9697L15.9696 17.0304L11.9999 13.0607L8.03026 17.0304L6.9696 15.9697Z"
        fill={color}
      />
    </svg>
  );
});

export default CircleCrossKnockoutIcon;
