import React from "react";
import classnames from "classnames";

import { CaretRightIcon, MenuIcon } from "storefront/ui/icons";

import { Typography } from "storefront/ui/typography";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import { Drawer, DrawerTrigger, DrawerContent } from "../Drawer";

import styles from "./MobileMenu.module.scss";

type MobileMenuLinkProps = {
  href: string;
  label: string;
  className?: string;
};

const MobileMenuLink = ({ href, label, className }: MobileMenuLinkProps) => (
  <a className={classnames(styles.item, className)} href={href}>
    <Typography variant="title2">{label}</Typography>
    <CaretRightIcon className={styles.caret} />
  </a>
);

const MobileMenuMerchandisingLinks = () => {
  const holidayGifts2024EnabledResource = useFeatureFlag(
    Feature.HOLIDAY_GIFTS_2024,
  );

  const holidayLink = React.useMemo(() => {
    if (holidayGifts2024EnabledResource.type === "Loading") {
      return null;
    }

    if (
      holidayGifts2024EnabledResource.type === "Completed" &&
      holidayGifts2024EnabledResource.value
    ) {
      return (
        <MobileMenuLink
          href="/drycleanonly/the-holiday-edit"
          className={styles.red}
          label="Holiday Gifts"
        />
      );
    }
    return <MobileMenuLink href="/shop/staff-picks" label="Staff Picks" />;
  }, [holidayGifts2024EnabledResource]);
  return (
    <div>
      <MobileMenuLink href="/designers" label="Designers" />
      <MobileMenuLink href="/categories/all" label="Menswear" />
      <MobileMenuLink href="/categories/womenswear/all" label="Womenswear" />
      <MobileMenuLink href="/categories/sneakers" label="Sneakers" />
      {holidayLink}
      <MobileMenuLink href="/collections" label="Collections" />
      <MobileMenuLink href="/drycleanonly" label="Editorial" />
    </div>
  );
};

type MobileMenuSubLinkProps = {
  href: string;
  label: string;
};

const MobileMenuSubLink = ({ href, label }: MobileMenuSubLinkProps) => (
  <a className={styles.subItem} href={href}>
    <Typography variant="footnote">{label}</Typography>
  </a>
);

const MobileMenuSubLinks = () => (
  <div>
    <MobileMenuSubLink href="https://help.grailed.com/hc/en-us" label="HELP" />
    <MobileMenuSubLink href="/about" label="ABOUT" />
    <MobileMenuSubLink href="/about/accessibility" label="ACCESSIBILITY" />
    <MobileMenuSubLink href="/about/terms" label="TERMS" />
    <MobileMenuSubLink href="/about/privacy" label="PRIVACY" />
  </div>
);

const MobileMenu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  return (
    <Drawer open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
      <DrawerTrigger className={styles.trigger}>
        <MenuIcon />
      </DrawerTrigger>
      <DrawerContent title="Merchandising menu" className={styles.content}>
        <MobileMenuMerchandisingLinks />
        <MobileMenuSubLinks />
      </DrawerContent>
    </Drawer>
  );
};

export default MobileMenu;
