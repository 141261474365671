import * as React from "react";
import {
  Root as NavigationMenu,
  List as NavigationMenuList,
  Item as NavigationMenuItem,
  Trigger as NavigationMenuTrigger,
  Content as NavigationMenuContent,
  Viewport as NavigationMenuViewport,
  Link as NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import cn from "classnames";

import ToggleCaret from "storefront/components/Icons/ToggleCaret";
import { Typography } from "storefront/ui/typography";
import { MENSWEAR, WOMENSWEAR } from "storefront/Department";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";

import DesignersDropdown from "./components/DesignersDropdown";
import CategoryDropdown from "./components/CategoryDropdown";

import styles from "./MerchandisingMenu.module.scss";

const Designers = () => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
        <Typography variant="title-sm">DESIGNERS</Typography>{" "}
        <ToggleCaret className={styles.caret} aria-hidden="true" />
      </NavigationMenuTrigger>
      <NavigationMenuContent className={styles.content}>
        <DesignersDropdown />
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

const MerchandisingMenu = () => {
  const holidayGifts2024EnabledResource = useFeatureFlag(
    Feature.HOLIDAY_GIFTS_2024,
  );
  const holidayLink = React.useMemo(() => {
    if (holidayGifts2024EnabledResource.type === "Loading") {
      return null;
    }

    if (
      holidayGifts2024EnabledResource.type === "Completed" &&
      holidayGifts2024EnabledResource.value
    ) {
      return (
        <NavigationMenuItem>
          <NavigationMenuLink
            className={cn(styles.item, styles.red)}
            href="/drycleanonly/the-holiday-edit"
          >
            <Typography variant="title-sm">HOLIDAY GIFTS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
      );
    }

    return (
      <NavigationMenuItem>
        <NavigationMenuLink className={styles.item} href="/shop/staff-picks">
          <Typography variant="title-sm">STAFF PICKS</Typography>
        </NavigationMenuLink>
      </NavigationMenuItem>
    );
  }, [holidayGifts2024EnabledResource]);
  return (
    <NavigationMenu className={styles.root}>
      <NavigationMenuList className={styles.list}>
        <Designers />
        <NavigationMenuItem>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">MENSWEAR</Typography>{" "}
            <ToggleCaret className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent className={styles.content}>
            <CategoryDropdown department={MENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">WOMENSWEAR</Typography>{" "}
            <ToggleCaret className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent className={styles.content}>
            <CategoryDropdown department={WOMENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/sneakers">
            <Typography variant="title-sm">SNEAKERS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        {holidayLink}
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/collections">
            <Typography variant="title-sm">COLLECTIONS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/drycleanonly">
            <Typography variant="title-sm">EDITORIAL</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
      <div className={styles.viewportContainer}>
        <NavigationMenuViewport className={styles.viewport} />
      </div>
    </NavigationMenu>
  );
};

export default MerchandisingMenu;
