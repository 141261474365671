import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const MessagesOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <g clipPath="url(#clip0_2014_5233)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.59246 16H19.1716L20 15.1716V5.82843L19.1716 5H4.82843L4 5.82843V16H4.59246ZM2 22V5L4 3H20L22 5V16L20 18H5.5L2 22Z"
            fill={color}
          />
        </g>
        ,
        <defs>
          <clipPath id="clip0_2014_5233">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default MessagesOutlineIcon;
