import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "storefront/ui/utils";
import { typographyVariants } from "storefront/ui/typography";

const buttonVariants = cva(
  "inline-flex justify-center items-center disabled:opacity-50 border border-solid focus-visible:ring-2 focus-visible:ring-blue-500 ring-offset-background focus-visible:ring-offset-2 whitespace-nowrap transition-colors cursor-pointer focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary:
          "bg-primary text-white border-primary hover:bg-darkest-gray hover:border-darkest-gray",
        secondary:
          "bg-white text-primary border-light-gray hover:bg-off-white hover:border-primary",
        important:
          "bg-important text-white border-important hover:bg-alert-new hover:border-alert-new",
        icon: "border-0 bg-transparent [&>svg]:w-[100%] [&>svg]:h-[100%]",
      },
      size: {
        small: "py-[8px] px-[16px]",
        medium: "py-[5px] px-[16px]",
        large: "py-[13px] min-w-[200px]",
      },
    },
    compoundVariants: [
      {
        variant: ["primary", "secondary", "important"],
        size: "small",
        class: typographyVariants({ variant: "button-sm" }),
      },
      {
        variant: ["primary", "secondary", "important"],
        size: "medium",
        class: typographyVariants({ variant: "callout-display" }),
      },
      {
        variant: ["primary", "secondary", "important"],
        size: "large",
        class: typographyVariants({ variant: "button-lg" }),
      },
      {
        variant: "icon",
        size: "small",
        class: "p-0 w-[16px] h-[16px]",
      },
      {
        variant: "icon",
        size: "medium",
        class: "p-0 w-[24px] h-[24px]",
      },
      {
        variant: "icon",
        size: "large",
        class: "p-0 min-w-[auto] w-[32px] h-[32px]",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "large",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
