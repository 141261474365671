import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const TrustedSellerIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.8585 14.4604C5.40649 13.0926 4.49998 11.1522 4.49998 9C4.49998 4.85786 7.85785 1.5 12 1.5C16.1421 1.5 19.5 4.85786 19.5 9C19.5 11.1522 18.5935 13.0926 17.1415 14.4603L20.9951 21.3969L16.8308 20.3558L14.8681 23.2999L12 16.8467L9.13189 23.2999L7.16914 20.3558L3.00488 21.3969L6.8585 14.4604ZM18 9C18 12.3137 15.3137 15 12 15C8.68628 15 5.99998 12.3137 5.99998 9C5.99998 5.68629 8.68628 3 12 3C15.3137 3 18 5.68629 18 9ZM10.5729 16.3644C9.66714 16.1899 8.81941 15.8525 8.0614 15.3838L5.99511 19.1031L7.83085 18.6442L8.86811 20.2001L10.5729 16.3644ZM15.9386 15.3838C15.1806 15.8525 14.3328 16.1899 13.4271 16.3644L15.1319 20.2001L16.1691 18.6442L18.0049 19.1031L15.9386 15.3838Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default TrustedSellerIcon;
