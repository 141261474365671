import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const DollarSignIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
          fill={color}
        />
        <path
          d="M13.0692 6.75H10.8589V7.70651C10.2469 7.806 9.70449 7.99064 9.26682 8.2639C8.56181 8.70407 8.14767 9.36308 8.14767 10.214C8.14767 11.1138 8.57264 11.6875 9.23593 12.0689C9.87725 12.4377 10.732 12.6206 11.5749 12.7822L11.6467 12.7961C12.3471 12.9319 12.8759 13.0345 13.2387 13.1887C13.4208 13.266 13.5436 13.3497 13.622 13.4445C13.6982 13.5367 13.7441 13.6536 13.7441 13.8165C13.7441 14.1088 13.5818 14.3072 13.3047 14.4428C13.0149 14.5847 12.5993 14.6553 12.1297 14.6553C11.4719 14.6553 11.03 14.5613 10.74 14.3813C10.4595 14.2073 10.2837 13.9287 10.222 13.4799L10.1984 13.3085H7.87473L7.88256 13.5087C7.91767 14.406 8.32502 15.1282 9.08061 15.6211C9.55293 15.9292 10.151 16.1411 10.8589 16.2555V17.25H13.0692V16.305C13.8178 16.213 14.478 15.9964 14.9938 15.6571C15.706 15.1887 16.125 14.4975 16.125 13.6257C16.125 13.168 16.0194 12.787 15.819 12.4685C15.6184 12.1497 15.3297 11.9047 14.9829 11.7106C14.3 11.3283 13.3722 11.1346 12.4038 10.9562C11.694 10.8259 11.2116 10.7185 10.9052 10.5737C10.7573 10.5039 10.6638 10.4311 10.6058 10.3529C10.5488 10.2759 10.5153 10.1793 10.5153 10.0457C10.5153 9.83415 10.6356 9.65579 10.8786 9.52217C11.1285 9.3847 11.5084 9.29675 11.9971 9.29675C12.4528 9.29675 12.8318 9.39068 13.1039 9.56159C13.3711 9.72943 13.5531 9.98234 13.5996 10.3364L13.6223 10.5088H15.9177L15.8989 10.3003C15.8217 9.44559 15.4149 8.76739 14.7289 8.306C14.278 8.00278 13.7167 7.79995 13.0692 7.69737V6.75Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default DollarSignIcon;
