// import { loading } from "storefront/lib/Resource";
import getUnreadCounts from "storefront/GrailedAPI/v1/Conversations/getUnreadCounts";

import useAuthentication from "./useAuthentication";
import useData from "./data/useData";

const useUnreadCounts = () => {
  const auth = useAuthentication();
  return useData(getUnreadCounts, {
    shouldFetch: auth.type === "Authenticated",
  });
};

export default useUnreadCounts;
