import React from "react";

import { SearchIcon } from "storefront/ui/icons";

import styles from "./MobileSearch.module.scss";

const MobileSearch = () => {
  return (
    <div className={styles.wrapper}>
      <button type="button" className={styles.button} aria-label="Open search">
        <SearchIcon />
      </button>
    </div>
  );
};

export default MobileSearch;
