import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ColorIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8839 1.5H11.1161L5.49362 7.45775C2.1355 11.0161 2.2589 16.6122 5.77062 20.0191C9.24113 23.386 14.7589 23.386 18.2294 20.0191C21.7411 16.6122 21.8645 11.0161 18.5064 7.45775L12.8839 1.5ZM12 3H12.237L17.4155 8.48727C20.2106 11.449 20.1079 16.1068 17.1849 18.9424C15.7406 20.3436 13.8703 21.0442 12 21.0442V3Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ColorIcon;
