import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const SearchIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <g clipPath="url(#clip0_2014_5245)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6762 17.1493C13.3618 18.1535 11.7193 18.75 9.9375 18.75C5.62278 18.75 2.125 15.2522 2.125 10.9375C2.125 6.62278 5.62278 3.125 9.9375 3.125C14.2522 3.125 17.75 6.62278 17.75 10.9375C17.75 12.7193 17.1535 14.3617 16.1494 15.6761L20.57 20.0967L19.0968 21.5699L14.6762 17.1493ZM15.6667 10.9375C15.6667 14.1016 13.1016 16.6667 9.9375 16.6667C6.77337 16.6667 4.20833 14.1016 4.20833 10.9375C4.20833 7.77337 6.77337 5.20833 9.9375 5.20833C13.1016 5.20833 15.6667 7.77337 15.6667 10.9375Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2014_5245">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default SearchIcon;
