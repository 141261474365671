import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CircleCheckIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 21C16.9706 21 21.0001 16.9706 21.0001 12C21.0001 7.02944 16.9706 3 12.0001 3C7.0295 3 3.00006 7.02944 3.00006 12C3.00006 16.9706 7.0295 21 12.0001 21ZM12.0001 22.5C17.7991 22.5 22.5001 17.799 22.5001 12C22.5001 6.20101 17.7991 1.5 12.0001 1.5C6.20107 1.5 1.50006 6.20101 1.50006 12C1.50006 17.799 6.20107 22.5 12.0001 22.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7693 14.7559L15.6447 6.70485L17.3555 7.74082L11.732 17.0272H9.91192L6.67879 12.3732L8.32133 11.2322L10.7693 14.7559Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CircleCheckIcon;
