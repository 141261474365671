import React from "react";

import MobileSearch from "./components/MobileSearch";
import DesktopSearch from "./components/DesktopSearch";

const Search = () => {
  return (
    <>
      <MobileSearch />
      <DesktopSearch />
    </>
  );
};

export default Search;
