import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ShieldGrailedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4934 3L3.75 5.58114V10.6592C3.75 15.4396 6.89417 19.6415 11.4612 21H12.5388C17.1058 19.6415 20.25 15.4396 20.25 10.6592V5.58114L12.5066 3H11.4934ZM12.75 1.5H11.25L2.25 4.5V10.6592C2.25 16.1796 5.93103 21.0225 11.25 22.5H12.75C18.069 21.0225 21.75 16.1796 21.75 10.6592V4.5L12.75 1.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4822 16.3254L14.4388 15.5129C14.1933 15.8112 13.9156 16.0345 13.6048 16.1926C13.1729 16.4123 12.689 16.5 12.1614 16.5C10.8247 16.5 9.77249 16.0282 9.03193 15.2337C8.29427 14.4302 7.875 13.3162 7.875 12.0233C7.875 10.5987 8.39176 9.33979 9.32987 8.49934C10.0593 7.85028 11.0119 7.5 12.1972 7.5C13.3055 7.5 14.1929 7.79725 14.841 8.31881C15.4891 8.84044 15.8838 9.57508 16.0271 10.4243L16.0603 10.6216H14.0191L13.9878 10.4902C13.8998 10.1204 13.7123 9.80988 13.428 9.59124C13.144 9.37282 12.7506 9.23637 12.2331 9.23637C11.4811 9.23637 10.9299 9.53703 10.5611 10.0256C10.1876 10.5205 9.99278 11.2207 9.99278 12.0233C9.99278 12.845 10.2155 13.5421 10.6006 14.0296C10.9828 14.5132 11.5302 14.7986 12.2092 14.7986C13.3858 14.7986 14.1546 13.9884 14.2285 13.2058H12.1783V11.5625H16.125V16.3254H14.4822Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ShieldGrailedIcon;
