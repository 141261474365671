import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CircleCrossIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <circle cx="12" cy="12" r="10.5" fill="#E1E1E1" />,
        <path
          d="M10.9393 12.0001L6.9696 15.9697L8.03026 17.0304L11.9999 13.0607L15.9696 17.0304L17.0303 15.9697L13.0606 12.0001L17.0303 8.03039L15.9696 6.96973L11.9999 10.9394L8.03026 6.96973L6.9696 8.03039L10.9393 12.0001Z"
          fill="#343434"
        />
      </svg>
    );
  },
);

export default CircleCrossIcon;
