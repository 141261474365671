import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const WarningIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13.125 8.625H10.875L11.1562 14.625H12.8438L13.125 8.625Z"
          fill={color}
        />
        <path d="M10.875 16.125H13.125V18.375H10.875V16.125Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3752 3.75L2.50962 19.4353L3.05278 20.25H20.9472L21.4904 19.4353L12.6248 3.75H11.3752ZM13.5 2.25H10.5L0.75 19.5L2.25 21.75H21.75L23.25 19.5L13.5 2.25Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default WarningIcon;
