import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const StarFilledIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M15 8.4375L12.9375 1.5H11.0625L9 8.4375H1.6875L1.125 10.125L6.9375 14.4375L4.6875 21.375L6.1875 22.5L12 18.1875L17.8125 22.5L19.3125 21.375L17.0625 14.4375L22.875 10.125L22.3125 8.4375H15Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default StarFilledIcon;
