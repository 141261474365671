import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const FireIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M20 16.75C18.875 19.75 16.5099 22.5 12.0188 22.5C7.52772 22.5 4.625 19.5 4.12499 16.75C3.3874 12.6934 6.24999 12.5 5.50001 7.87497C8.37501 9.37497 8.50001 11.375 8.12501 13.125C9.50001 9.49997 10.75 5.62497 7.74999 1.62497C10.8485 1.62497 13.0037 2.60195 14.875 5.37497C16.7463 8.14799 17.375 12.625 16 15.25C17.125 14.375 18.25 13.375 18.875 9.87497C20.375 10.875 21.125 13.75 20 16.75Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinejoin="bevel"
        />
      </svg>
    );
  },
);

export default FireIcon;
