import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CaretLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.25 11.3107L15.0303 6.53033L13.9697 5.46967L8.75 10.6893V13.3107L13.9697 18.5303L15.0303 17.4697L10.25 12.6893V11.3107Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CaretLeftIcon;
