import React from "react";
import cn from "classnames";

import authWrapper, {
  AuthWrapperProps,
} from "storefront/components/Authentication/authWrapper";
import { Button } from "storefront/ui/button";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import { HeartOutlineIcon, MessagesOutlineIcon } from "storefront/ui/icons";
import { Id } from "storefront/lib/Id";
import { Typography } from "storefront/ui/typography";
import useUnreadCounts from "storefront/hooks/useUnreadCounts";
import useAuthentication from "storefront/hooks/useAuthentication";
import { GLOBAL_HEADER_LOGIN_ID } from "storefront/components/Page/Header/Menu/Login";
import { GLOBAL_HEADER_SIGNUP_ID } from "storefront/components/Page/Header/Menu/Signup";

import Profile from "../Profile";
import styles from "./Actions.module.scss";

const LogInButton = authWrapper(({ authenticated }: AuthWrapperProps) => {
  const onLoginClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    authenticated(() => {}, {
      modalTrigger: "log_in_button",
      message: "log_in",
      verifications: new Set(["email"]),
    })();
  };
  return (
    <Button
      size="small"
      className={styles.actionButton}
      onClick={onLoginClick}
      variant="primary"
      id={GLOBAL_HEADER_LOGIN_ID}
    >
      LOG IN
    </Button>
  );
});

const Login = () => <LogInButton authType="LogIn" />;

const SignupButton = authWrapper(({ authenticated }: AuthWrapperProps) => {
  const onSignupClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    authenticated(() => {}, {
      modalTrigger: "sign_up_button",
      verifications: new Set(["email"]),
    })();
  };
  return (
    <Button
      size="small"
      className={styles.actionButton}
      onClick={onSignupClick}
      variant="secondary"
      id={GLOBAL_HEADER_SIGNUP_ID}
    >
      SIGN UP
    </Button>
  );
});

const Signup = () => <SignupButton authType="CreateAccount" />;

const LoggedOutActions = () => {
  return (
    <>
      <Signup />
      <Login />
    </>
  );
};

type LoggedInActionsProps = {
  userId: Id;
  username: string;
  avatarUrl?: string;
};

const LoggedInActions = ({
  userId,
  username,
  avatarUrl,
}: LoggedInActionsProps) => {
  const { track } = useAnalytics();
  const unreadCountsResource = useUnreadCounts();

  const hasUnreadMessages =
    unreadCountsResource.type === "Completed" &&
    (unreadCountsResource.value.buying.unreadCount > 0 ||
      unreadCountsResource.value.selling.unreadCount > 0);

  return (
    <>
      <a
        href="/foryou"
        onClick={() => track(navigationClicked("For You", "global_header"))}
        className={cn(styles.action, styles.forYou)}
      >
        <Typography variant="title-sm">FOR YOU</Typography>
      </a>
      <a
        href="/messages"
        onClick={() => track(navigationClicked("Messages", "global_header"))}
        className={cn(styles.action, {
          [styles.notified]: hasUnreadMessages,
        })}
      >
        <MessagesOutlineIcon />
      </a>
      <a
        href={`/mygrails/${userId}`}
        onClick={() => track(navigationClicked("Favorites", "global_header"))}
        className={styles.action}
      >
        <HeartOutlineIcon />
      </a>
      <Profile avatarUrl={avatarUrl} userId={userId} username={username} />
    </>
  );
};

const SellButton = authWrapper(({ authenticated }: AuthWrapperProps) => {
  const { track } = useAnalytics();
  const onSellClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    track(navigationClicked("Sell", "global_header"));

    authenticated(
      () => {
        window.location.href = "/sell/new";
      },
      {
        modalTrigger: "sell_button",
        reload: false,
        verifications: new Set(["email"]),
      },
    )();
  };
  return (
    <a
      href="/sell/new"
      className={cn(styles.action, styles.border, styles.sell)}
      onClick={onSellClick}
      data-testid="desktop-sell"
    >
      <Typography variant="title-sm">SELL</Typography>
    </a>
  );
});

export const Actions = () => {
  const auth = useAuthentication();

  return (
    <div className={styles.actions}>
      <SellButton />
      {auth.type !== "Authenticated" ? (
        <LoggedOutActions />
      ) : (
        <LoggedInActions
          avatarUrl={auth.user.avatarUrl}
          userId={auth.user.id}
          username={auth.user.username}
        />
      )}
    </div>
  );
};

export default Actions;
