import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const LockIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 13.125C11.1716 13.125 10.5 13.7966 10.5 14.625C10.5 15.1802 10.8016 15.665 11.25 15.9243V18H12.75V15.9243C13.1984 15.665 13.5 15.1802 13.5 14.625C13.5 13.7966 12.8284 13.125 12 13.125Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 6V9H5.25L3 11.25V20.25L5.25 22.5H18.75L21 20.25V11.25L18.75 9H16.5V6C16.5 3.51472 14.4853 1.5 12 1.5C9.51472 1.5 7.5 3.51472 7.5 6ZM15 9V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V9H15ZM5.87132 10.5H18.1287L19.5 11.8713V19.6287L18.1287 21H5.87132L4.5 19.6287V11.8713L5.87132 10.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default LockIcon;
