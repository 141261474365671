import React from "react";
import dynamic from "next/dynamic";

import { Id } from "storefront/lib/Id";
import useMediaQuery, {
  LARGER_THAN_TABLET,
} from "storefront/hooks/useMediaQuery";

import styles from "./Profile.module.scss";

const MobileProfile = dynamic(() => import("./components/MobileProfile"));
const DesktopProfile = dynamic(() => import("./components/DesktopProfile"));

type ProfileMenuContextType = {
  profileMenuOpen: boolean;
  onOpenChange: (open: boolean) => void;
  avatarUrl: string;
  userId: Id;
  username: string;
};

const ProfileMenuContext = React.createContext<
  ProfileMenuContextType | undefined
>(undefined);

export const useProfileMenu = () => {
  const context = React.useContext(ProfileMenuContext);
  if (!context) {
    throw new Error("useProfileMenu must be used within a ProfileMenuProvider");
  }
  return context;
};

type ProfileProps = {
  userId: Id;
  username: string;
  avatarUrl?: string;
};

const fallbackAvatarUrl =
  "https://media-assets.grailed.com/prd/misc/PVEFHQYACL92VZTZETOTVQNFENXZW7S5U0MLUG";

const Profile = ({ avatarUrl, userId, username }: ProfileProps) => {
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const isDesktop = useMediaQuery(LARGER_THAN_TABLET);
  return (
    <ProfileMenuContext.Provider
      value={{
        profileMenuOpen,
        onOpenChange: setProfileMenuOpen,
        avatarUrl: avatarUrl ?? fallbackAvatarUrl,
        userId,
        username,
      }}
    >
      <div className={styles.avatar}>
        {isDesktop !== null && isDesktop ? <DesktopProfile /> : null}
        {isDesktop !== null && !isDesktop ? <MobileProfile /> : null}
      </div>
    </ProfileMenuContext.Provider>
  );
};

export default Profile;
