import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowTopLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2501 6.375H7.60607L6.37507 7.60601V14.25H7.87507V8.93566L16.3447 17.4053L17.4054 16.3447L8.93573 7.875H14.2501V6.375Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowTopLeftIcon;
