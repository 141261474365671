import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const VerifiedUserIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 4.125L12.75 1.5H11.25L8.625 4.125H5.25L4.125 5.25V8.625L1.5 11.25V12.75L4.125 15.375V18.75L5.25 19.875H8.625L11.25 22.5H12.75L15.375 19.875H18.75L19.875 18.75V15.375L22.5 12.75V11.25L19.875 8.625V5.25L18.75 4.125H15.375ZM18.1287 5.625H14.7537L12.1287 3H11.8713L9.24632 5.625H5.87132L5.625 5.87132V9.24632L3 11.8713V12.1287L5.625 14.7537V18.1287L5.87132 18.375H9.24632L11.8713 21H12.1287L14.7537 18.375H18.1287L18.375 18.1287V14.7537L21 12.1287V11.8713L18.375 9.24632V5.87132L18.1287 5.625Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4822 16.3254L14.4388 15.5129C14.1933 15.8112 13.9156 16.0345 13.6048 16.1926C13.1729 16.4123 12.689 16.5 12.1614 16.5C10.8247 16.5 9.7725 16.0282 9.03193 15.2337C8.29428 14.4302 7.875 13.3162 7.875 12.0233C7.875 10.5987 8.39176 9.33979 9.32987 8.49934C10.0593 7.85028 11.0119 7.5 12.1972 7.5C13.3055 7.5 14.1929 7.79725 14.841 8.31881C15.4891 8.84044 15.8838 9.57508 16.0271 10.4243L16.0603 10.6216H14.0191L13.9878 10.4902C13.8998 10.1204 13.7123 9.80988 13.428 9.59124C13.144 9.37282 12.7506 9.23637 12.2331 9.23637C11.4811 9.23637 10.9299 9.53703 10.5611 10.0256C10.1876 10.5205 9.99278 11.2207 9.99278 12.0233C9.99278 12.845 10.2155 13.5421 10.6006 14.0296C10.9828 14.5132 11.5302 14.7986 12.2092 14.7986C13.3858 14.7986 14.1546 13.9884 14.2285 13.2058H12.1783V11.5625H16.125V16.3254H14.4822Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default VerifiedUserIcon;
