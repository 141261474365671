import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const AdminIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 4.125L12.75 1.5H11.25L8.625 4.125H5.25L4.125 5.25V8.625L1.5 11.25V12.75L4.125 15.375V18.75L5.25 19.875H8.625L11.25 22.5H12.75L15.375 19.875H18.75L19.875 18.75V15.375L22.5 12.75V11.25L19.875 8.625V5.25L18.75 4.125H15.375ZM18.1287 5.625H14.7537L12.1287 3H11.8713L9.24632 5.625H5.87132L5.625 5.87132V9.24632L3 11.8713V12.1287L5.625 14.7537V18.1287L5.87132 18.375H9.24632L11.8713 21H12.1287L14.7537 18.375H18.1287L18.375 18.1287V14.7537L21 12.1287V11.8713L18.375 9.24632V5.87132L18.1287 5.625Z"
          fill={color}
        />
        <path
          d="M7.6875 16.3125H9.89015L10.3975 14.7564H13.4911L14.0108 16.3125H16.3125L13.1199 7.6875H10.8677L7.6875 16.3125ZM10.9049 13.2244L11.5855 11.1375C11.7339 10.6912 11.9443 9.91914 11.9443 9.91914H11.9691C11.9691 9.91914 12.1671 10.6912 12.3155 11.1375L12.9838 13.2244H10.9049Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default AdminIcon;
