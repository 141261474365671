import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const FlagIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.857 5.23438C10.035 4.49499 8.0169 4.39116 6.12857 4.93963L5.75 5.04959V14.8147L6.0312 14.733C8.03544 14.1509 10.1774 14.2611 12.1113 15.0459C13.9306 15.7841 15.9616 15.8076 17.7975 15.1115L18.25 14.9399V5.66887L18.0382 5.7183C15.9772 6.19918 13.818 6.03013 11.857 5.23438ZM5.71018 3.49916C7.92235 2.85662 10.2865 2.97827 12.421 3.84446C14.0949 4.52372 15.9381 4.66802 17.6973 4.25754L18.8296 3.99334L19.75 4.72373V15.4576L19.2659 16.1589L18.3293 16.514C16.1396 17.3443 13.7171 17.3163 11.5473 16.4358C9.92584 15.7778 8.12999 15.6854 6.44959 16.1735L4.25 16.8124V4.48644L4.7908 3.7662L5.71018 3.49916Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.75 6V21H4.25V6H5.75Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default FlagIcon;
