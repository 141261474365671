import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ChevronRightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 11.3107L7.96967 4.53033L9.03033 3.46967L16.25 10.6893V13.3107L9.03033 20.5303L7.96967 19.4697L14.75 12.6893V11.3107Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ChevronRightIcon;
