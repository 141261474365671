import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const HeartOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.71058H11.5L10.402 6.62665C8.88032 5.12449 6.40486 5.12449 4.88318 6.62665C3.37227 8.1182 3.37227 10.5288 4.88318 12.0203L11.9535 19H12.0465L19.1168 12.0203C20.6277 10.5288 20.6277 8.1182 19.1168 6.62665C17.5951 5.12449 15.1197 5.12449 13.598 6.62665L12.5 7.71058H12ZM12.6621 20.5H11.3379L3.82939 13.0878C1.72354 11.0089 1.72354 7.63803 3.82939 5.55917C5.93524 3.48032 9.34994 3.48032 11.4558 5.55917L12 6.0964L12.5442 5.55917C14.6501 3.48032 18.0648 3.48032 20.1706 5.55917C22.2765 7.63803 22.2765 11.0089 20.1706 13.0878L12.6621 20.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default HeartOutlineIcon;
