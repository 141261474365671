import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const BoutiqueBagIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.08387 8.25L3.82466 18.9533L5.12132 20.25H18.8787L20.1753 18.9533L18.9161 8.25H5.08387ZM20.25 6.75H3.75L2.25 19.5L4.5 21.75H19.5L21.75 19.5L20.25 6.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 6C7.5 3.51472 9.51472 1.5 12 1.5C14.4853 1.5 16.5 3.51472 16.5 6V10.5H15V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V10.5H7.5V6Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default BoutiqueBagIcon;
