import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const PlusIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.75 3H11.25V11.25H3V12.75H11.25V21H12.75V12.75H21V11.25H12.75V3Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default PlusIcon;
