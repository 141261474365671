import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const EyeOpenedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1318 11.3265L22.1319 12.6733C20.9179 16.1752 16.8417 18.75 12 18.75C7.15847 18.75 3.08227 16.1753 1.86825 12.6735L1.86823 11.3267C3.08215 7.82477 7.15842 5.25 12.0001 5.25C16.8416 5.25 20.9178 7.82466 22.1318 11.3265ZM12 17.25C7.49874 17.25 4.09728 14.817 3.22645 12.0001C4.0972 9.18306 7.4987 6.75 12.0001 6.75C16.5014 6.75 19.9028 9.18296 20.7736 11.9999C19.9029 14.8169 16.5014 17.25 12 17.25Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default EyeOpenedIcon;
