import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowBottomDownIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9394 19.3125H13.0607L17.5929 14.7803L16.5322 13.7197L12.7501 17.5018V5.4375H11.2501V17.5018L7.46789 13.7197L6.40723 14.7803L10.9394 19.3125Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowBottomDownIcon;
