import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const EyeClosedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M20.3211 4.75024L19.2604 3.68958L3.70773 19.2423L4.76839 20.3029L20.3211 4.75024Z"
          fill={color}
        />
        <path
          d="M12.0001 5.25C13.268 5.25 14.4835 5.42659 15.6088 5.75017L14.3729 6.98612C13.6255 6.83301 12.8315 6.75 12.0001 6.75C7.4987 6.75 4.0972 9.18306 3.22645 12.0001C3.62817 13.2996 4.56842 14.5173 5.90674 15.4522L4.8311 16.5279C3.42954 15.4984 2.3873 14.1707 1.86825 12.6735L1.86823 11.3267C3.08215 7.82477 7.15842 5.25 12.0001 5.25Z"
          fill={color}
        />
        <path
          d="M12 7.5C12.5542 7.5 13.0851 7.60021 13.5755 7.78351L7.78347 13.5755C7.60018 13.0852 7.49996 12.5543 7.49996 12C7.49996 9.51472 9.51468 7.5 12 7.5Z"
          fill={color}
        />
        <path
          d="M8.40773 18.2545L9.64482 17.0175C10.3871 17.1683 11.1751 17.25 12 17.25C16.5014 17.25 19.9029 14.8169 20.7736 11.9999C20.3732 10.7045 19.4375 9.49023 18.1058 8.55647L19.1812 7.48109C20.5766 8.50929 21.6143 9.83363 22.1318 11.3265L22.1319 12.6733C20.9179 16.1752 16.8417 18.75 12 18.75C10.7382 18.75 9.52844 18.5751 8.40773 18.2545Z"
          fill={color}
        />
        <path
          d="M10.44 16.2223L16.2222 10.44C16.4019 10.9261 16.5 11.4516 16.5 12C16.5 14.4853 14.4852 16.5 12 16.5C11.4515 16.5 10.926 16.4019 10.44 16.2223Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default EyeClosedIcon;
