import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const FilterIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 4.5H19.4055C19.0725 3.20608 17.8979 2.25 16.5 2.25C15.1021 2.25 13.9275 3.20608 13.5945 4.5L1.5 4.5V6L13.5945 6C13.9275 7.29392 15.1021 8.25 16.5 8.25C17.8979 8.25 19.0725 7.29392 19.4055 6H22.5V4.5ZM18 5.25C18 6.07843 17.3284 6.75 16.5 6.75C15.6716 6.75 15 6.07843 15 5.25C15 4.42157 15.6716 3.75 16.5 3.75C17.3284 3.75 18 4.42157 18 5.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 18H19.4055C19.0725 16.7061 17.8979 15.75 16.5 15.75C15.1021 15.75 13.9275 16.7061 13.5945 18L1.5 18V19.5L13.5945 19.5C13.9275 20.7939 15.1021 21.75 16.5 21.75C17.8979 21.75 19.0725 20.7939 19.4055 19.5H22.5V18ZM18 18.75C18 19.5784 17.3284 20.25 16.5 20.25C15.6716 20.25 15 19.5784 15 18.75C15 17.9216 15.6716 17.25 16.5 17.25C17.3284 17.25 18 17.9216 18 18.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 15C8.89788 15 10.0725 14.0439 10.4055 12.75L22.5 12.75V11.25L10.4055 11.25C10.0725 9.95608 8.89788 9 7.5 9C6.10212 9 4.92755 9.95608 4.59451 11.25H1.5V12.75H4.59451C4.92755 14.0439 6.10212 15 7.5 15ZM7.5 13.5C8.32843 13.5 9 12.8284 9 12C9 11.1716 8.32843 10.5 7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default FilterIcon;
