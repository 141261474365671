import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CriclePlusKnockoutIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={forwardedRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM11.25 11.25V6H12.75V11.25H18V12.75H12.75V18H11.25V12.75H6V11.25H11.25Z"
        fill={color}
      />
    </svg>
  );
});

export default CriclePlusKnockoutIcon;
