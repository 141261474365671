import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CriclePlusIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <circle cx="12" cy="12" r="10.5" fill="#E1E1E1" />,
        <path
          d="M12.75 6H11.25V11.25H6V12.75H11.25V18H12.75V12.75H18V11.25H12.75V6Z"
          fill="#343434"
        />
      </svg>
    );
  },
);

export default CriclePlusIcon;
