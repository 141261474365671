import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowBottomRightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.74993 17.625H16.3939L17.6249 16.394V9.75H16.1249V15.0643L7.65526 6.59467L6.5946 7.65533L15.0643 16.125H9.74993V17.625Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowBottomRightIcon;
