import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ChevronLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 11.3107L16.0303 4.53033L14.9697 3.46967L7.75 10.6893V13.3107L14.9697 20.5303L16.0303 19.4697L9.25 12.6893V11.3107Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ChevronLeftIcon;
