import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6875 13.4356L4.6875 11.3143L9.21967 6.78211L10.2803 7.84277L6.49816 11.6249L18.5625 11.6249L18.5625 13.1249L6.49816 13.1249L10.2803 16.9071L9.21967 17.9678L4.6875 13.4356Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowLeftIcon;
