import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "storefront/ui/utils";

const typographyVariants = cva("m-0 font-base", {
  variants: {
    variant: {
      "button-lg":
        "font-video uppercase text-[16px] font-semibold tracking-[0.32px] leading-[22px]",
      "button-sm":
        "font-video uppercase text-[11px] font-bold tracking-[0.44px] leading-[14px]",
      "callout-display":
        "font-video text-[14px] font-semibold tracking-[0.28px] leading-[20px] uppercase",
      "price-sm":
        "font-video uppercase text-[13px] font-semibold tracking-[0.26px] leading-[18px]",
      "price-lg": "font-video uppercase text-[24px] font-bold leading-[34px]",
      "price-xl": "font-video uppercase text-[48px] font-bold leading-[64px]",
      "sub-head": "text-[14px] font-bold tracking-[0.14px] leading-[20px]",
      "title-sm": "text-[12px] font-bold tracking-[0.12px] leading-[16px]",
      body: "text-[16px] font-normal tracking-[0.32px] leading-[21px]",
      callout: "text-[14px] font-normal tracking-[0.28px] leading-[18px]",
      footnote: "text-[12px] font-normal leading-[14px]",
      headline: "text-[16px] font-bold tracking-[0.16px] leading-[21px]",
      timestamp: "text-[10px] font-normal leading-[13px] tracking-[0.2px]",
      title1: "text-[32px] font-semibold leading-[38px]",
      title2: "text-[20px] font-semibold leading-[24px]",
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  as?: string;
}

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  ({ className, variant, as = "div", asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : as;
    return (
      <Comp
        className={cn(typographyVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Typography.displayName = "Typography";

export { Typography, typographyVariants };
