import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { cn } from "storefront/ui/utils";

const Dialog = DialogPrimitive.Root;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogContent = DialogPrimitive.Content;

const DialogTitle = DialogPrimitive.Title;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, key, ...props }, ref) => (
  <DialogPrimitive.Overlay
    key={String(key)}
    ref={ref}
    className={cn(
      "fixed inset-0 z-[9999] bg-black/60 data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=closed]:duration-500 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogTrigger = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>
>(({ className, key, children, ...props }, ref) => (
  <DialogPrimitive.Trigger
    key={String(key)}
    ref={ref}
    className={cn(
      "bg-transparent text-inherit border-none p-0 cursor-pointer appearance-none shadow-none",
      className,
    )}
    {...props}
  >
    {children}
  </DialogPrimitive.Trigger>
));
DialogTrigger.displayName = DialogPrimitive.Overlay.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogTitle,
};
