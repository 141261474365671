import * as React from "react";
import { clsx, ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

type PossibleRef<T> = React.Ref<T> | undefined;

/**
 * A utility to make it easier to conditionally add Tailwind CSS classes
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Set a given ref to a given value
 * This utility takes care of different types of refs: callback refs and RefObject(s)
 */
function setRef<T>(ref: PossibleRef<T>, value: T) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-param-reassign
    (ref as React.MutableRefObject<T>).current = value;
  }
}

/**
 * A utility to compose multiple refs together
 * Accepts callback refs and RefObject(s)
 */
export function composeRefs<T>(...refs: PossibleRef<T>[]) {
  return (node: T) => refs.forEach((ref) => setRef(ref, node));
}
