import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CircleCheckFilledIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <circle cx="12" cy="12" r="10.5" fill={color} fillOpacity="0.06" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7693 14.7559L15.6447 6.70486L17.3554 7.74084L11.732 17.0272H9.9119L6.67877 12.3733L8.32131 11.2322L10.7693 14.7559Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CircleCheckFilledIcon;
