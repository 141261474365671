import React from "react";
import cn from "classnames";
import { UserSelf } from "storefront/User";
import SmallTitle from "storefront/components/SmallTitle";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import Item from "../../Item";
import * as SubItems from "./SubItems";
import styles from "./Account.module.scss";

type Props = {
  onClick: (e: React.SyntheticEvent<HTMLElement>) => void;
  isOpen: boolean;
  currentUser: UserSelf;
};

const Account = ({ onClick, isOpen, currentUser }: Props) => {
  const { track } = useAnalytics();

  const trackNav = (name: string) => {
    track(navigationClicked(name, "global_header"));
  };

  return (
    <Item
      label={currentUser.username}
      userForAvatar={currentUser}
      onClick={onClick}
      isOpen={isOpen}
      className={styles.username}
    >
      <SubItems.Messages trackNav={trackNav} />
      <SubItems.MyGrails trackNav={trackNav} currentUser={currentUser} />
      <SubItems.Purchases trackNav={trackNav} />

      <SmallTitle className={cn("SubItemHeader", styles.header)}>
        Sell
      </SmallTitle>
      <SubItems.ForSale trackNav={trackNav} />
      <SubItems.Sold trackNav={trackNav} />
      <SubItems.Drafts trackNav={trackNav} />
      <SubItems.Feedback trackNav={trackNav} />
      <SubItems.VacationMode trackNav={trackNav} />
      <SubItems.Settings trackNav={trackNav} />

      <SmallTitle className={cn("SubItemHeader", styles.header)}>
        My Account
      </SmallTitle>
      <SubItems.MyProfile trackNav={trackNav} />
      <SubItems.MyAddresses trackNav={trackNav} />
      <SubItems.MySizes trackNav={trackNav} />
      <SubItems.Notifications trackNav={trackNav} />
      <SubItems.Devices trackNav={trackNav} />
      <SubItems.Payments trackNav={trackNav} />
    </Item>
  );
};

export default Account;
