import * as React from "react";

import { cn } from "storefront/ui/utils";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input">>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "p-0 font-base text-[14px] placeholder:text-secondary disabled:pointer-events-none disabled:opacity-50 focus:outline-none flex border-[1px] focus-visible::border-darkest-gray bg-white px-[12px] border-light-gray border-solid focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-full h-[40px] transition-colors cursor-text",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
