import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CameraIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 6.75H2.25L0.75 8.25V19.5L2.25 21H21.75L23.25 19.5V8.25L21.75 6.75H18.75L18.5 6.5L17.25 4.25L16.5 3.75H7.5L6.75 4.25L5.5 6.5L5.25 6.75ZM7.88463 5.29636L6.70921 7.41211L5.87132 8.25H2.87132L2.25 8.87132V18.8787L2.87132 19.5H21.1287L21.75 18.8787V8.87132L21.1287 8.25H18.1287L17.2908 7.41211L16.1154 5.29636L16.0458 5.25H7.95416L7.88463 5.29636Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 16.4999C13.7949 16.4999 15.25 15.0449 15.25 13.2499C15.25 11.455 13.7949 9.99994 12 9.99994C10.2051 9.99994 8.74999 11.455 8.74999 13.2499C8.74999 15.0449 10.2051 16.4999 12 16.4999ZM12 17.9999C14.6233 17.9999 16.75 15.8733 16.75 13.2499C16.75 10.6266 14.6233 8.49994 12 8.49994C9.37664 8.49994 7.24999 10.6266 7.24999 13.2499C7.24999 15.8733 9.37664 17.9999 12 17.9999Z"
          fill={color}
        />
        ,<path d="M18.25 9.75H20.5V11.25H18.25V9.75Z" fill={color} />
      </svg>
    );
  },
);

export default CameraIcon;
