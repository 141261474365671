import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowDownUpIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.12322 19.3125H7.0019L2.46973 14.7803L3.53039 13.7197L7.31256 17.5018V5.4375H8.81256V17.5018L12.5947 13.7197L13.6554 14.7803L9.12322 19.3125Z"
          fill={color}
        />
        <path
          d="M16.9982 4.6875H14.8769L10.3447 9.21967L11.4054 10.2803L15.1876 6.49816V18.5625H16.6876V6.49816L20.4697 10.2803L21.5304 9.21967L16.9982 4.6875Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowDownUpIcon;
