import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const LocationIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1287 21L16.3768 16.7518C18.4633 14.6653 19.5 12.5869 19.5 10.5C19.5 6.07843 16.4216 3 12 3C7.57843 3 4.5 6.07843 4.5 10.5C4.5 12.5736 5.7106 14.8393 7.62316 16.7518L11.8713 21H12.1287ZM12.75 22.5H11.25L6.5625 17.8125C4.5 15.75 3 13.125 3 10.5C3 5.25 6.75 1.5 12 1.5C17.25 1.5 21 5.25 21 10.5C21 13.125 19.6875 15.5625 17.4375 17.8125L12.75 22.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6.75C13.4497 6.75 14.625 7.92525 14.625 9.375C14.625 10.8247 13.4497 12 12 12C10.5503 12 9.375 10.8247 9.375 9.375C9.375 7.92525 10.5503 6.75 12 6.75ZM16.125 9.375C16.125 7.09683 14.2782 5.25 12 5.25C9.72183 5.25 7.875 7.09683 7.875 9.375C7.875 11.6532 9.72183 13.5 12 13.5C14.2782 13.5 16.125 11.6532 16.125 9.375Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default LocationIcon;
