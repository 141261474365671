import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CrossIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M18.8943 6.16637L17.8336 5.10571L12 10.9393L6.16637 5.10571L5.10571 6.16637L10.9393 12L5.10571 17.8336L6.16637 18.8943L12 13.0607L17.8336 18.8943L18.8943 17.8336L13.0607 12L18.8943 6.16637Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CrossIcon;
