import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const HeartFilledIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3.82939 5.55917C1.72354 7.63803 1.72354 11.0089 3.82939 13.0878L11.3379 20.5H12.6621L20.1706 13.0878C22.2765 11.0089 22.2765 7.63803 20.1706 5.55917C18.0648 3.48032 14.6501 3.48032 12.5442 5.55917L12 6.0964L11.4558 5.55917C9.34994 3.48032 5.93524 3.48032 3.82939 5.55917Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default HeartFilledIcon;
