import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CogIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.25L9.25 0.5H12.75L14 3.25L14.375 3.375L17.25 2.75L19.25 4.75L18.625 7.625L18.75 8L21.5 9.25V12.75L18.75 14L18.625 14.375L19.25 17.25L17.25 19.25L14.375 18.625L14 18.75L12.75 21.5H9.25L8 18.75L7.625 18.625L4.75 19.25L2.75 17.25L3.375 14.375L3.25 14L0.5 12.75V9.25L3.25 8L3.375 7.625L2.75 4.75L4.75 2.75L7.625 3.375L8 3.25ZM5.23159 4.38973L7.70871 4.92823L9.09488 4.46618L10.2159 2H11.7841L12.9051 4.46618L14.2913 4.92823L16.7684 4.38973L17.6103 5.23159L17.0718 7.70871L17.5338 9.09488L20 10.2159V11.7841L17.5338 12.9051L17.0718 14.2913L17.6103 16.7684L16.7684 17.6103L14.2913 17.0718L12.9051 17.5338L11.7841 20H10.2159L9.09488 17.5338L7.70871 17.0718L5.23159 17.6103L4.38973 16.7684L4.92823 14.2913L4.46618 12.9051L2 11.7841V10.2159L4.46618 9.09488L4.92823 7.70871L4.38973 5.23159L5.23159 4.38973Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14ZM11 15.5C13.4853 15.5 15.5 13.4853 15.5 11C15.5 8.51472 13.4853 6.5 11 6.5C8.51472 6.5 6.5 8.51472 6.5 11C6.5 13.4853 8.51472 15.5 11 15.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CogIcon;
