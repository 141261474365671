import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const SpeedyShipperIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <g clipPath="url(#clip0_2014_5166)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 6H4.5V4.5H18V7.5H19.5L22.5 12V18H20.122C19.8131 18.8739 18.9797 19.5 18 19.5C17.0203 19.5 16.1869 18.8739 15.878 18H11.122C10.8131 18.8739 9.97966 19.5 9 19.5C8.02034 19.5 7.18691 18.8739 6.87803 18H4.5V16.5H6.87803C7.18691 15.6261 8.02034 15 9 15C9.97966 15 10.8131 15.6261 11.122 16.5H15.878C16.0052 16.1402 16.2213 15.8223 16.5 15.5729V6ZM18.75 17.25C18.75 17.6642 18.4142 18 18 18C17.5858 18 17.25 17.6642 17.25 17.25C17.25 16.8358 17.5858 16.5 18 16.5C18.4142 16.5 18.75 16.8358 18.75 17.25ZM20.122 16.5C19.8131 15.6261 18.9797 15 18 15V13.5H21V16.5H20.122ZM9.75 17.25C9.75 17.6642 9.41421 18 9 18C8.58579 18 8.25 17.6642 8.25 17.25C8.25 16.8358 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.8358 9.75 17.25ZM18 12H20.6972L18.6972 9H18V12Z"
            fill={color}
          />
          ,<path d="M10.5 7.5H0V9H10.5V7.5Z" fill={color} />,
          <path d="M13.5 10.5H4.5V12H13.5V10.5Z" fill={color} />,
          <path d="M1.5 13.5H6V15H1.5V13.5Z" fill={color} />
        </g>
        ,
        <defs>
          <clipPath id="clip0_2014_5166">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default SpeedyShipperIcon;
