import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CaretDownIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6893 13.75L17.4697 8.96967L18.5303 10.0303L13.3107 15.25L10.6893 15.25L5.46968 10.0303L6.53033 8.96967L11.3107 13.75L12.6893 13.75Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CaretDownIcon;
