import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const QuickResponderIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.234 10.5L13.8596 5.08121L6.49315 13.5H11.766L10.1404 18.9188L17.5068 10.5H12.234ZM19.5 10.5L9 22.5H7.5L9.75 15H5.25L4.5 13.5L15 1.5H16.5L14.25 9H18.75L19.5 10.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default QuickResponderIcon;
