import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const BoutiqueStoreIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.07094 4.875H18.9291L19.8291 3.75H4.17094L5.07094 4.875ZM3.75 5.625L2.25 3.75V2.25H21.75V3.75L20.25 5.625V21.75H3.75V5.625ZM5.25 20.25V6.375H18.75V20.25H5.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 13.875H9.75V20.25H14.25V13.875ZM8.25 12.375V21.75H15.75V12.375H8.25Z"
          fill={color}
        />
        ,<path d="M7.5 8.625H16.5V10.125H7.5V8.625Z" fill={color} />
      </svg>
    );
  },
);

export default BoutiqueStoreIcon;
