import React from "react";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import VideoLogo from "storefront/components/Logo";

const Logo = () => {
  const { track } = useAnalytics();
  return (
    <a
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        track(navigationClicked("grailed_logo", "global_header"));
      }}
      href="/"
    >
      <VideoLogo />
    </a>
  );
};

export default Logo;
