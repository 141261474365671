import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import AvatarWithFallback from "storefront/components/AvatarWithFallback";
import { UserSelf } from "storefront/User";
import TrackableMenuItem from "../../TrackableMenuItem";
import hasNotificationsSelector from "../../hasNotificationsSelector";
import DropdownMenu from "./DropdownMenu";
import styles from "./index.module.scss";

const className = (hasNotifications: boolean): string =>
  cn("--profile", {
    _notified: hasNotifications,
  });

type Props = {
  user: UserSelf;
};

const Profile = ({ user }: Props) => {
  const { track } = useAnalytics();

  const hasNotifications = useSelector(hasNotificationsSelector);

  return (
    <div className={className(hasNotifications)}>
      <TrackableMenuItem
        href="/messages"
        onClick={() =>
          track(navigationClicked("Profile User", "global_header"))
        }
        className={styles.profile}
        label="Profile User"
      >
        <AvatarWithFallback user={user} />
      </TrackableMenuItem>

      <DropdownMenu className="DropdownMenu" />
    </div>
  );
};

export default Profile;
