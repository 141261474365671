import React from "react";

import useAnalytics from "storefront/hooks/useAnalytics";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import useData from "storefront/hooks/data/useData";
import fetchBySlugs from "storefront/Algolia/Designers/fetchBySlugs";
import { Typography } from "storefront/ui/typography";

import styles from "./DesignersDropdown.module.scss";

const FAVORITE_DESIGNER_SLUGS = [
  "acne-studios",
  "adidas",
  "alexander-mcqueen",
  "amiri",
  "balenciaga",
  "bape",
  "bottega-veneta",
  "celine",
  "chanel",
  "chrome-hearts",
  "comme-des-garcons",
  "dior",
  "dries-van-noten",
  "fear-of-god",
  "gucci",
  "jacquemus",
  "kapital",
  "louis-vuitton",
  "maison-margiela",
  "nike",
  "number-n-ine",
  "off-white",
  "prada",
  "raf-simons",
  "rick-owens",
  "saint-laurent-paris",
  "stone-island",
  "supreme",
  "undercover",
  "vintage",
  "loewe",
];

const useDesignersBySlugs = (slugs: string[]) => {
  return useData(fetchBySlugs, { args: [slugs] });
};

const DesignersDropdown = () => {
  const designerSlugsResource = useDesignersBySlugs(FAVORITE_DESIGNER_SLUGS);
  const { track } = useAnalytics();

  const trackClick = (name: string) => {
    const from = "sub_header_designer_dropdown";
    track(buttonClicked(name, from));
    track(navigationClicked(name, from));
  };

  const sortedDesigners = React.useMemo(() => {
    if (designerSlugsResource.type === "Completed") {
      return designerSlugsResource.value.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    }
    return [];
  }, [designerSlugsResource]);
  return (
    <div className={styles.container}>
      <a href="/designers" className={styles.link}>
        <Typography variant="title2" className={styles.index}>
          Designers A-Z
        </Typography>
      </a>
      <div className={styles.designers}>
        {sortedDesigners.map((designer) => (
          <a
            key={designer.id}
            className={styles.link}
            href={`/designers/${designer.slug}`}
            onClick={() => trackClick(designer.name)}
          >
            <Typography variant="callout">{designer.name}</Typography>
          </a>
        ))}
      </div>
    </div>
  );
};

export default DesignersDropdown;
