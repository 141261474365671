import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

import { from as visitorPropertiesFrom } from "storefront/Analytics/VisitorProperties";
import { memoizedMeRequest } from "storefront/hooks/useAuthentication";
import AlgoliaAutocomplete from "storefront/components/Page/Header/AlgoliaAutocomplete";
import CategoriesNavigation from "storefront/components/Page/Header/CategoriesNavigation";
import Logo from "storefront/components/Page/Header/Logo";
import Menu from "storefront/components/Page/Header/Menu";
import MenuExpandedContext from "storefront/components/Page/Header/MenuExpandedContext";
import pageView from "storefront/Analytics/EventCreators/pageView";
import SlideOutMenu from "storefront/components/Page/Header/SlideOutMenu";
import styles from "storefront/components/Page/Header/Header.module.scss";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import type { Value } from "storefront/components/Page/Header/MenuExpandedContext";
import useAnalytics from "storefront/hooks/useAnalytics";
import useCurrentDevice from "storefront/hooks/useCurrentDevice";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import SiteHeader from "../SiteHeader";

const Navigation = React.memo(() => (
  <>
    <CategoriesNavigation />
    <SlideOutMenu />
  </>
));

/**
 * @name Header
 * @description Returns the Global Header
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const LegacyHeader = () => {
  const { identify, track } = useAnalytics();
  const device = useCurrentDevice();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState<boolean>(false);
  const isMobile = device === "mobile";

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    memoizedMeRequest().then((user) => {
      identify(user, visitorPropertiesFrom(window, user));
    });
    track(pageView());
  }, [identify, track]);

  return (
    <header
      className={classnames(styles.pageHeader, {
        [styles.stickyHeader]: isMobile,
      })}
    >
      <div className={styles.frame}>
        <div
          id="globalHeaderWrapper"
          className={classnames(styles.wrapper, {
            [styles.stickyWrapper]: !isMobile,
          })}
          ref={headerRef}
        >
          <div className={styles.content}>
            <Logo />
            {mounted ? (
              <>
                <SuspenseWithFragment>
                  <AlgoliaAutocomplete />
                </SuspenseWithFragment>
                <Menu />
              </>
            ) : null}
          </div>
        </div>
        {mounted ? <Navigation /> : null}
      </div>
    </header>
  );
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
const not = (v) => !!v;

const ProvidedHeader = () => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const defaultValue: Value = {
    isExpanded,
    toggleExpanded: () => setExpanded(not),
    setExpanded,
  };
  return (
    <MenuExpandedContext.Provider value={defaultValue}>
      <LegacyHeader />
    </MenuExpandedContext.Provider>
  );
};

const Header = () => {
  const webImprovedIAFeatureResource = useFeatureFlag(Feature.WEB_IMPROVED_IA);
  const improvedIAFeatureEnabled =
    webImprovedIAFeatureResource.type === "Completed" &&
    webImprovedIAFeatureResource.value;

  return improvedIAFeatureEnabled ? <SiteHeader /> : <ProvidedHeader />;
};

export default Header;
