import React from "react";

import Logo from "./components/Logo";
import MobileMenu from "./components/MobileMenu";
import Search from "./components/Search";
import Actions from "./components/Actions";
import MerchandisingMenu from "./components/MerchandisingMenu";

import styles from "./SiteHeader.module.scss";

const SiteHeader = () => {
  return (
    <header className={styles.header} id="globalHeader">
      <nav className={styles.nav} aria-label="global">
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.menu}>
            <MobileMenu />
          </div>
          <div className={styles.actions}>
            <Actions />
          </div>
          <div className={styles.search}>
            <Search />
          </div>
        </div>
      </nav>
      <div className={styles.merchandisingMenu}>
        <MerchandisingMenu />
      </div>
    </header>
  );
};

export default SiteHeader;
