import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const CircleCheckKnockoutIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={forwardedRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM15.6446 6.70486L10.7692 14.7559L8.32127 11.2322L6.67873 12.3733L9.91186 17.0272H11.732L17.3554 7.74084L15.6446 6.70486Z"
        fill={color}
      />
    </svg>
  );
});

export default CircleCheckKnockoutIcon;
