import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const MessagesFilledIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path d="M2 5L4 3H20L22 5V16L20 18H5.5L2 22V5Z" fill={color} />
      </svg>
    );
  },
);

export default MessagesFilledIcon;
