import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const StarHalfIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9375 1.5L15 8.4375H22.3125L22.875 10.125L17.0625 14.4375L19.3125 21.375L17.8125 22.5L12 18.1875L6.1875 22.5L4.6875 21.375L6.9375 14.4375L1.125 10.125L1.6875 8.4375H9L11.0625 1.5H12.9375ZM12 16.3197L17.394 20.3217L15.3032 13.875L20.6103 9.9375H13.8811L12 3.61039L12 16.3197Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default StarHalfIcon;
