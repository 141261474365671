import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const MenuIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <rect width="24" height="24" fill="white" />,
        <path d="M3 5H21V6.5H3V5Z" fill={color} />,
        <path d="M3 11.5H21V13H3V11.5Z" fill={color} />,
        <path d="M3 18H21V19.5H3V18Z" fill={color} />
      </svg>
    );
  },
);

export default MenuIcon;
