import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const InfoIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
          fill={color}
        />
        <path
          d="M15 15V16.875H9.375V15H11.25V12H9.75V10.125H13.125V15H15Z"
          fill={color}
        />
        <path d="M11.1562 6.75H13.2188V8.8125H11.1562V6.75Z" fill={color} />
      </svg>
    );
  },
);

export default InfoIcon;
