import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ShieldVerifiedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17.0625 9.84375L11.4375 15.4688H9.9375L6.9375 12.4688L8.25 11.1562L10.6875 13.5938L15.75 8.53125L17.0625 9.84375Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4934 3L3.75 5.58114V10.6592C3.75 15.4396 6.89417 19.6415 11.4612 21H12.5388C17.1058 19.6415 20.25 15.4396 20.25 10.6592V5.58114L12.5066 3H11.4934ZM12.75 1.5H11.25L2.25 4.5V10.6592C2.25 16.1796 5.93103 21.0225 11.25 22.5H12.75C18.069 21.0225 21.75 16.1796 21.75 10.6592V4.5L12.75 1.5Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ShieldVerifiedIcon;
