import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ShareIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.68934 8.5H10V10H7.31066L6.75 10.5607V18.9393L7.31066 19.5H16.6893L17.25 18.9393V10.5607L16.6893 10H14V8.5H17.3107L18.75 9.93934V19.5607L17.3107 21H6.68934L5.25 19.5607V9.93934L6.68934 8.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9393 3H13.0607L16.5303 6.46967L15.4697 7.53033L12.75 4.81066V15H11.25V4.81066L8.53033 7.53033L7.46967 6.46967L10.9393 3Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ShareIcon;
