import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowUpIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9394 4.6875H13.0607L17.5929 9.21967L16.5322 10.2803L12.7501 6.49816V18.5625H11.2501V6.49816L7.46789 10.2803L6.40723 9.21967L10.9394 4.6875Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowUpIcon;
