import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const ArrowRightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3125 13.4356L19.3125 11.3143L14.7803 6.78211L13.7197 7.84277L17.5018 11.6249L5.4375 11.6249L5.4375 13.1249L17.5018 13.1249L13.7197 16.9071L14.7803 17.9678L19.3125 13.4356Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default ArrowRightIcon;
