import React from "react";
import classnames from "classnames";

import { Department, WOMENSWEAR } from "storefront/Department";
import useCategories from "storefront/hooks/useCategories";
import titleCase from "storefront/lib/String/titleCase";

import { Typography } from "storefront/ui/typography";

import { CaretRightIcon } from "storefront/ui/icons";
import styles from "./CategoryDropdown.module.scss";

type CategoryDropdownProps = {
  department: Department;
};

const CategoryDropdown = ({ department }: CategoryDropdownProps) => {
  const categoriesResource = useCategories();

  if (categoriesResource.type !== "Completed") return null;

  const categories = Object.values(categoriesResource.value).filter(
    (category) => category.department === department,
  );

  const departmentName = titleCase(department);

  return (
    <div className={styles.container}>
      <a
        href={
          department === WOMENSWEAR
            ? `/categories/${WOMENSWEAR}/all`
            : "/categories/all"
        }
        className={classnames(styles.link, styles.title)}
      >
        <Typography variant="title2">Shop All {departmentName}</Typography>
      </a>
      <ul className={styles.categoryList}>
        {categories.map((category) => (
          <li key={category.name}>
            <a
              href={`/categories/${category.slug}`}
              className={classnames(styles.link, styles.category)}
            >
              <Typography variant="sub-head">
                {titleCase(category.name)}
              </Typography>
              <CaretRightIcon className={styles.caret} />
            </a>
            <ul className={styles.subcategoryList}>
              {category.subcategories.map((subcategory) => (
                <li key={subcategory.name} className={styles.subcategory}>
                  <a
                    href={`/categories/${subcategory.slug}`}
                    className={styles.link}
                  >
                    <Typography variant="title-sm">
                      {subcategory.name}
                    </Typography>
                  </a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryDropdown;
